import { ActionTypes } from '../actions/Shared';

const initialState = {
  flowManagementSettings: null,
};

const FlowManagementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_FLOW_MANAGEMENT_SUCCESS:
      return { ...state, flowManagementSettings: action.payload };
    case ActionTypes.ADD_FLOW_MANAGEMENT_SUCCESS:
      return {
        ...state,
        flowManagementSettings: {
          ...state.flowManagementSettings,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.DELETE_FLOW_MANAGEMENT_SUCCESS:
      return {
        ...state,
        flowManagementSettings: {
          ...Object.values(state.flowManagementSettings).filter((item) => item.id !== action.payload.id).reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {}),
        },
      };
    case ActionTypes.EDIT_FLOW_MANAGEMENT_SUCCESS:
      return {
        ...state,
        flowManagementSettings: {
          ...state.flowManagementSettings,
          [action.payload.id]: action.payload,
        },
      };
    case ActionTypes.FETCH_FLOW_MANAGEMENT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default FlowManagementReducer;
