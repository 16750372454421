import { combineReducers } from 'redux';
import LocationReducer from './LocationReducer';
import EquipmentReducer from './EquipmentReducer';
import TrailsReducer from './TrailsReducer';
import HydrantsReducer from './HydrantsReducer';
import AuthReducer from './AuthReducer';
import TrendReducer from './TrendReducer';
import ForecastReducer from './ForecastReducer';
import GatewaysReducer from './GatewaysReducer';
import GunsReducer from './GunsReducer';
import MobileGunsReducer from './MobileGunsReducer';
import QualityReducer from './QualityReducer';
import NavbarReducer from './NavbarReducer';
import WeatherStationReducer from './WeatherStationReducer';
import ZonesReducer from './ZonesReducer';
import FlowManagementReducer from './FlowManagementReducer';
import SettingsReducer from './SettingsReducer';
import PLCReducer from './PLCReducer';
import MapReducer from './MapReducer';
import TrackerEquipmentReducer from './TrackerEquipmentReducer';
import SnowDepthReducer from './SnowDepthReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  locations: LocationReducer,
  equipment: EquipmentReducer,
  trails: TrailsReducer,
  hydrants: HydrantsReducer,
  trends: TrendReducer,
  forecast: ForecastReducer,
  gateways: GatewaysReducer,
  presets: QualityReducer,
  guns: GunsReducer,
  mobileGuns: MobileGunsReducer,
  nav: NavbarReducer,
  weatherStations: WeatherStationReducer,
  flowManagementSettings: FlowManagementReducer,
  zones: ZonesReducer,
  settings: SettingsReducer,
  plcs: PLCReducer,
  map: MapReducer,
  trackerEquipment: TrackerEquipmentReducer,
  snowDepths: SnowDepthReducer,
});

export default rootReducer;
