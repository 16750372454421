import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withTheme, ThemeProvider } from '@emotion/react';
import { Languages, LanguageContext } from 'isno/lib/languages/LanguageContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import { LightTheme, DarkTheme, OriginalTheme } from 'isno/lib/themes';
import Routes from '../components/Routes';

const muiThemeDark = createTheme({
  palette: {
    mode: 'dark',
    color: '#7591FF',
    textColor: '#7591FF',
    selectionColor: '#7591FF',
    handleFillColor: '#7591FF',
    selectColor: '#7591FF',
    selectTextColor: '#7591FF',
    calendarYearBackgroundColor: '#7591FF',
    headerColor: '#7591FF',
    primary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
    secondary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
  },
});

const muiThemeLight = createTheme({
  palette: {
    color: '#7591FF',
    textColor: '#7591FF',
    selectionColor: '#7591FF',
    handleFillColor: '#7591FF',
    selectColor: '#7591FF',
    selectTextColor: '#7591FF',
    calendarYearBackgroundColor: '#7591FF',
    headerColor: '#7591FF',
    primary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
    secondary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
  },
});

const muiTheme = createTheme({
  palette: {
    color: '#7591FF',
    textColor: '#7591FF',
    selectionColor: '#7591FF',
    handleFillColor: '#7591FF',
    selectColor: '#7591FF',
    selectTextColor: '#7591FF',
    calendarYearBackgroundColor: '#7591FF',
    headerColor: '#7591FF',
    primary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
    secondary: {
      light: '#7591FF',
      main: '#4B73FF',
    },
  },
});

const themes = { LightTheme, DarkTheme, OriginalTheme };
const muiThemes = { LightTheme: muiThemeLight, DarkTheme: muiThemeDark, OriginalTheme: muiTheme };

function App(props) {
  const [language, setLanguage] = useState(Languages.english);
  // const [theme, setTheme] = useState('LightTheme');
  let currentTheme = props.settings?.settings?.theme;
  if (!Object.keys(themes).some((theme) => (theme === currentTheme))) {
    currentTheme = 'DarkTheme';
  }
  useEffect(() => {
    // If our currentTheme isn't one of the options in our themes then default to dark theme
    const portalTarget = document.getElementById('portalPlaceholder');
    if (portalTarget) {
      portalTarget.style.color = themes[currentTheme]?.textColor;
    }
  }, [props.settings?.settings?.theme]);

  const memoizedLanguageObj = useMemo(() => ({ language, setLanguage }), [language]);
  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <LanguageContext.Provider value={memoizedLanguageObj}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MuiThemeProvider theme={muiThemes[currentTheme]}>
            <Router>
              <Routes />
            </Router>
          </MuiThemeProvider>
        </LocalizationProvider>
      </LanguageContext.Provider>
    </ThemeProvider>
  );
}

App.propTypes = {
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      userId: PropTypes.number,
      theme: PropTypes.string,
      language: PropTypes.string,
      seasonStart: PropTypes.string,
      useCelsius: PropTypes.bool,
    }),
    // gunModels: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, {})(withTheme(App));
