import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  ValveConnect,
  Pond,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  InfoControl,
  MultiInfoButton,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { PumpsRowCustom } from 'isno/lib/components/layout/Rows';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchLocation(3),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  const locationEquipment3 = props.locations?.locations?.[3]?.equipment;
  const MirrorLakePLC = props.plcs?.plcs?.[2];
  if (!locationEquipment2 || !locationEquipment3 || !MirrorLakePLC) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  let mirrorLakeAlertText = null;
  if (locationEquipment3?.Other?.ioValues?.low_lake_level_alarm?.value) {
    mirrorLakeAlertText = 'Low Level Alarm';
  } else if (locationEquipment3?.Other?.ioValues?.low_lake_level_warning?.value) {
    mirrorLakeAlertText = 'Low Level Warning';
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Cold Brook">
            <InfoControlRow>
              {/* *** THIS WAS IN THE SCHEMATIC BUT WE DON'T HAVE ANY IO VALUES FOR IT *** */}
              {/* <InfoControl
                title="Instrument Air"
                statistic={locationEquipment2?.IA?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment2?.IA?.status ? theme.onGreen : theme.offRed}
                label=""
                setIOValue={props.setIOValue}
                button1WriteValue={1}
                button2WriteValue={0}
                button1WriteId={locationEquipment2?.IA?.ioValues?.disable_enable?.id ?? 0}
                button2WriteId={locationEquipment2?.IA?.ioValues?.disable_enable?.id ?? 0}
                // alarms={[
                //   locationEquipment1?.IA?.ioValues?.low_air_pressure,
                // ]}
              /> */}
              <Info
                title="IA Pressure"
                statistic={locationEquipment2?.IA?.ioValues?.instrument_air_pressure?.value}
                label={locationEquipment2?.IA?.ioValues?.instrument_air_pressure?.ioValueType?.units}
              />
              <Info
                precision={1}
                title="Room Temp"
                statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.room_temperature?.ioValueType?.units}
              />
              <MultiInfoButton
                title="Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment2?.FT?.ioValues?.trip_volume?.value,
                  locationEquipment2?.FT?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment2?.FT?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment2?.FT?.ioValues?.trip_volume_reset?.id,
                  locationEquipment2?.FT?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
            </InfoControlRow>
            <PumpsRowCustom height="150px">
              <div css={styles.pondContainer}>
                <Pond color={theme.pipeColors.water}>
                  <div css={styles.pondInfo}>
                    <div>
                      <Info
                        title="Basin Level 1"
                        statistic={locationEquipment2?.Other?.ioValues?.basin_level_1?.value}
                        precision={1}
                        label={locationEquipment2?.Other?.ioValues?.basin_level_1?.ioValueType?.units}
                      />
                      <Info
                        title="Basin Level 2"
                        statistic={locationEquipment2?.Other?.ioValues?.basin_level_2?.value}
                        precision={1}
                        label={locationEquipment2?.Other?.ioValues?.basin_level_2?.ioValueType?.units}
                      />
                    </div>
                    <Pump
                      psi
                      componentMinWidth="35px"
                      percent
                      setIOValue={props.setIOValue}
                      pumpData={{ ...locationEquipment2.P1, name: 'P1' }}
                      otherData={locationEquipment2.Other}
                      stateKey={{
                        0: language.off,
                        1: language.on,
                        2: language.warning,
                        3: language.alarm,
                      }}
                      stateColorKey={{
                        0: `${theme.secondary}`,
                        1: `${theme.onGreen}`,
                        2: `${theme.warning}`,
                        3: `${theme.alarm}`,
                      }}
                      IOValueKeys={{
                        start: 'start',
                        stop: 'stop',
                        started: 'run_feedback',
                        stopped: 'run_feedback',
                      }}
                      writeValues={[1, 1]}
                      readValues={[1, 0]}
                      alarms={[
                        locationEquipment2?.P1?.ioValues?.fault,
                      ]}
                      stats={[
                        {
                          stat: locationEquipment2?.P1?.ioValues?.current?.value,
                          label: locationEquipment2?.P1?.ioValues?.current?.ioValueType?.units,
                        },
                        {
                          stat: locationEquipment2?.P1?.ioValues?.run_time?.value,
                          label: locationEquipment2?.P1?.ioValues?.run_time?.ioValueType?.units,
                        },
                      ]}
                    />
                  </div>
                </Pond>
              </div>
              <div css={styles.valvePairContainer}>
                <div css={styles.valvePair}>
                  <ValveConnect horizontal="above" valveData={{}} pipeBelow="left" />
                  <ValveConnect horizontal="below" valveData={{}} />
                </div>
                <div css={styles.valveVertical}>
                  <PipePlaceholder pipeAbove="left" />
                  <div>
                    <PipePlaceholder horizontalBelow />
                  </div>
                </div>
                <PipePlaceholder horizontalBelow>
                  <Statistic
                    float
                    floatColor={theme.pipeColors.water}
                    label={locationEquipment2?.Other?.ioValues?.river_water_flow?.ioValueType?.units}
                    border
                    statistic={locationEquipment2?.Other?.ioValues?.river_water_flow?.value}
                  />
                </PipePlaceholder>
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Mirror Lake">
            <InfoControlRow>
              {/* *** THIS WAS IN THE SCHEMATIC BUT WE DON'T HAVE ANY IO VALUES FOR IT *** */}
              {/* <InfoControl
                title="Instrument Air"
                statistic={locationEquipment3?.IA?.status ? 'Enabled' : 'Disabled'}
                color={locationEquipment3?.IA?.status ? theme.onGreen : theme.offRed}
                label=""
                setIOValue={props.setIOValue}
                button1WriteValue={1}
                button2WriteValue={0}
                button1WriteId={locationEquipment3?.IA?.ioValues?.disable_enable?.id ?? 0}
                button2WriteId={locationEquipment3?.IA?.ioValues?.disable_enable?.id ?? 0}
                // alarms={[
                //   locationEquipment1?.IA?.ioValues?.low_air_pressure,
                // ]}
              /> */}
              <Info
                title="IA Pressure"
                statistic={locationEquipment3?.IA?.ioValues?.instrument_air_pressure?.value}
                label={locationEquipment3?.IA?.ioValues?.instrument_air_pressure?.ioValueType?.units}
              />
              <Info
                precision={1}
                title="Room Temp"
                statistic={useCelsius ? locationEquipment3?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment3?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment3?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment3?.Other?.ioValues?.room_temperature?.ioValueType?.units}
              />
              <MultiInfoButton
                title="Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment3?.FT?.ioValues?.trip_volume?.value,
                  locationEquipment3?.FT?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment3?.FT?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment3?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment3?.FT?.ioValues?.trip_volume_reset?.id,
                  locationEquipment3?.FT?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <Info
                title="E-Stop"
                statistic={locationEquipment3?.Other?.ioValues?.['e-stop']?.value ? 'Active' : 'Inactive'}
                label=""
                color={locationEquipment3?.Other?.ioValues?.['e-stop']?.value ? `${theme.alarm}` : `${theme.secondary}`}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment3?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(MirrorLakePLC, language)}
                label=""
                color={PLCColorRouter(MirrorLakePLC, theme)}
              />
            </InfoControlRow>
            <PumpsRowSmall>
              <Pump
                psi
                componentMinWidth="35px"
                percent
                pipeAbove="right"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment3.P1, name: 'P1' }}
                otherData={locationEquipment3.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  // dischargePressure -> Not currently used, will be utilized once another vfd is installed
                  //   dischargePressure: 'back_water_pressure',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment3?.P1?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.P1?.ioValues?.current?.value,
                    label: locationEquipment3?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment3?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                psi
                componentMinWidth="35px"
                percent
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment3.P2, name: 'P2' }}
                otherData={locationEquipment3.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment3?.P2?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.P2?.ioValues?.current?.value,
                    label: locationEquipment3?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment3?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                psi
                componentMinWidth="35px"
                percent
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={{ ...locationEquipment3.P3, name: 'P3' }}
                otherData={locationEquipment3.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment3?.P3?.ioValues?.fault,
                ]}
                stats={[
                  {
                    stat: locationEquipment3?.P3?.ioValues?.current?.value,
                    label: locationEquipment3?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment3?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment3?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder horizontalAbove capAfter capLabel="To Main" capLabelLeft="-55px" capLabelTop="25px">
                <Statistic
                  border
                  statistic={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.value}
                  label={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                />
                <Statistic
                  border
                  statistic={locationEquipment3?.Other?.ioValues?.discharge_water_flow?.value}
                  label={locationEquipment3?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                />

              </PipePlaceholder>
            </PumpsRowSmall>
            <PumpsRowCustom height="65px">
              <Pond color={theme.pipeColors.water}>
                <Info
                  margin="0"
                  title="Lake Level"
                  statistic={locationEquipment3?.Other?.ioValues?.lake_level?.value}
                  precision={1}
                  label={locationEquipment3?.Other?.ioValues?.lake_level?.ioValueType?.units}
                  alertText={mirrorLakeAlertText}
                  alertTop="15px"
                  alertLeft="110px"
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    pondInfo: css`
      display: flex;
  `,
    pondContainer: css`
      flex: 2;
      height: 100%;
  `,
    valvePair: css`
      display: flex;
      height: 100%;
      flex-direction: column;
  `,
    valvePairContainer: css`
      display: flex;
      height: 100%;
      flex-direction: row;
      flex: 2;
      padding: 20px 0 10px 0;
  `,
    valveVertical: css`
      display: flex;
      height: 100%;
      flex-direction: column;
  `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
