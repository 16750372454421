import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Pipe,
  PipePlaceholder,
  ValveConnect,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Alarms,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  MobileStatTable,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  MultiInfoButton,
  SequenceControl,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

function PumpScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(4),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(4);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment4 = props.locations?.locations?.[4]?.equipment;
  const PLC1 = props.plcs?.plcs?.[1];
  if (!props.locations?.locations?.[4] || !PLC1) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Booster Pumphouse">
            <InfoControlRow>
              <MobileStatTable
                title="Pipe Metrics"
                stats={
                [
                  {
                    description: 'Suction Water Flow',
                    stat: locationEquipment4?.Other?.ioValues?.suction_water_flow?.value,
                    label: locationEquipment4?.Other?.ioValues?.suction_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Suction Water Pressure',
                    stat: locationEquipment4?.Other?.ioValues?.suction_water_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Oh No Pressure',
                    stat: locationEquipment4?.Other?.ioValues?.ohno_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.ohno_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Needle Pressure',
                    stat: locationEquipment4?.Other?.ioValues?.needle_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.needle_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Outcast Drain',
                    stat: locationEquipment4?.Other?.ioValues?.outcast_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.outcast_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Jennifers Drain',
                    stat: locationEquipment4?.Other?.ioValues?.jennifers_run_drain_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.jennifers_run_drain_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Last Chance Drain',
                    stat: locationEquipment4?.Other?.ioValues?.last_chance_drain_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.last_chance_drain_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Hayseed Drain',
                    stat: locationEquipment4?.Other?.ioValues?.hayseed_drain_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.hayseed_drain_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Lower Dutchman Drain',
                    stat: locationEquipment4?.Other?.ioValues?.lower_dutchman_drain_pressure?.value,
                    label: locationEquipment4?.Other?.ioValues?.lower_dutchman_drain_pressure?.ioValueType?.units,
                  },
                ]
              }
              />
              <div css={locationEquipment4?.Other?.ioValues?.sequence_lead_vfd?.value === -1 ? styles.hidden : []}>
                <VFDPumpControl
                  title="BP1 PID"
                  settings
                  inputNumWidth="64px"
                  pumpData={{ id: `controlValve_${locationEquipment4?.['BP1 PID']?.id}` }}
                  pidData={locationEquipment4?.['BP1 PID']}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'auto_manual',
                    manualMode: 'auto_manual',
                    autoManual: 'auto_manual',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                    iGainSetpoint: 'igain_setpoint',
                    pGainSetpoint: 'pgain_setpoint',
                  }}
                  readValues={[0, 1]}
                  writeValues={[0, 1]}
                />
              </div>
              <div css={locationEquipment4?.Other?.ioValues?.sequence_lead_vfd?.value === -1 ? styles.hidden : []}>
                <VFDPumpControl
                  title="BP2 PID"
                  settings
                  inputNumWidth="64px"
                  pumpData={{ id: `controlValve_${locationEquipment4?.['BP2 PID']?.id}` }}
                  pidData={locationEquipment4?.['BP2 PID']}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'auto_manual',
                    manualMode: 'auto_manual',
                    autoManual: 'auto_manual',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                    iGainSetpoint: 'igain_setpoint',
                    pGainSetpoint: 'pgain_setpoint',
                  }}
                  readValues={[0, 1]}
                  writeValues={[0, 1]}
                />
              </div>
              <div css={locationEquipment4?.Other?.ioValues?.sequence_lead_vfd?.value === -1 ? styles.hidden : []}>
                <VFDPumpControl
                  title="BP3 PID"
                  settings
                  inputNumWidth="64px"
                  pumpData={{ id: `controlValve_${locationEquipment4?.['BP3 PID']?.id}` }}
                  pidData={locationEquipment4?.['BP3 PID']}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'auto_manual',
                    manualMode: 'auto_manual',
                    autoManual: 'auto_manual',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                    iGainSetpoint: 'igain_setpoint',
                    pGainSetpoint: 'pgain_setpoint',
                  }}
                  readValues={[0, 1]}
                  writeValues={[0, 1]}
                />
              </div>
              <div css={locationEquipment4?.Other?.ioValues?.sequence_lead_vfd?.value === -1 ? styles.hidden : []}>
                <VFDPumpControl
                  title="BP4 PID"
                  settings
                  inputNumWidth="64px"
                  pumpData={{ id: `controlValve_${locationEquipment4?.['BP4 PID']?.id}` }}
                  pidData={locationEquipment4?.['BP4 PID']}
                  setIOValue={props.setIOValue}
                  max="1500"
                  IOValueKeys={{
                    autoMode: 'auto_manual',
                    manualMode: 'auto_manual',
                    autoManual: 'auto_manual',
                    autoSetpoint: 'auto_setpoint',
                    manualSetpoint: 'manual_setpoint',
                    iGainSetpoint: 'igain_setpoint',
                    pGainSetpoint: 'pgain_setpoint',
                  }}
                  readValues={[0, 1]}
                  writeValues={[0, 1]}
                />
              </div>
              <SequenceControl
                title="Sequencer"
                pumpsHeight="478px"
                pumpsWidth="193px"
                setIOValue={props.setIOValue}
                sequencerData={locationEquipment4?.Sequencer}
                leadVFD={locationEquipment4?.Sequencer?.ioValues?.sequence_lead_vfd}
                leadVFDId={
                  locationEquipment4?.Sequencer?.ioValues?.sequence_lead_vfd?.value === 0 ? locationEquipment4?.BP1?.id
                    : locationEquipment4?.Sequencer?.ioValues?.sequence_lead_vfd?.value === 1 ? locationEquipment4?.BP2?.id
                      : locationEquipment4?.Sequencer?.ioValues?.sequence_lead_vfd?.value === 2 ? locationEquipment4?.BP3?.id
                        : locationEquipment4?.BP4?.id
                }
                leadVFDlabel1={locationEquipment4?.BP1?.name}
                leadVFDlabel2={locationEquipment4?.BP2?.name}
                leadVFDlabel3={locationEquipment4?.BP3?.name}
                leadVFDlabel4={locationEquipment4?.BP4?.name}
                id={locationEquipment4?.Sequencer?.id}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                pumps={[
                  locationEquipment4?.BP1,
                  locationEquipment4?.BP2,
                  locationEquipment4?.BP3,
                  locationEquipment4?.BP4,
                ]}
                disableEnable={[
                  locationEquipment4?.Sequencer?.ioValues?.p1_disable_enable,
                  locationEquipment4?.Sequencer?.ioValues?.p2_disable_enable,
                  locationEquipment4?.Sequencer?.ioValues?.p3_disable_enable,
                  locationEquipment4?.Sequencer?.ioValues?.p4_disable_enable,
                ]}
                setpoints={[
                  locationEquipment4?.Sequencer?.ioValues?.p1_sequence_order_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.p2_sequence_order_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.p3_sequence_order_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.p4_sequence_order_setpoint,
                ]}
                startParam={[
                  locationEquipment4?.Sequencer?.ioValues?.start_fast_offset_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.start_fast_timer_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.start_slow_offset_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.start_slow_timer_setpoint,
                ]}
                startValues={[
                  locationEquipment4?.Sequencer?.ioValues?.start_fast_offset,
                  locationEquipment4?.Sequencer?.ioValues?.start_fast_timer,
                  locationEquipment4?.Sequencer?.ioValues?.start_slow_offset,
                  locationEquipment4?.Sequencer?.ioValues?.start_slow_timer,
                ]}
                startLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Timer',
                ]}
                stopParam={[
                  locationEquipment4?.Sequencer?.ioValues?.stop_fast_offset_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.stop_fast_timer_setpoint,
                  useCelsius ? locationEquipment4?.Sequencer?.ioValues?.stop_fast_seal_setpoint_alternate : locationEquipment4?.Sequencer?.ioValues?.stop_fast_seal_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.stop_fast_speed_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.stop_slow_offset_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.stop_slow_timer_setpoint,
                  useCelsius ? locationEquipment4?.Sequencer?.ioValues?.stop_slow_seal_setpoint_alternate : locationEquipment4?.Sequencer?.ioValues?.stop_slow_seal_setpoint,
                  locationEquipment4?.Sequencer?.ioValues?.stop_slow_speed_setpoint,
                ]}
                stopValues={[
                  locationEquipment4?.Sequencer?.ioValues?.stop_fast_offset,
                  locationEquipment4?.Sequencer?.ioValues?.stop_fast_timer,
                  null,
                  null,
                  locationEquipment4?.Sequencer?.ioValues?.stop_slow_offset,
                  locationEquipment4?.Sequencer?.ioValues?.stop_slow_timer,
                  null,
                  null,
                ]}
                stopLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Fast Seal',
                  'Fast Speed',
                  'Slow Offset',
                  'Slow Timer',
                  'Slow Seal',
                  'Slow Speed',
                ]}
              />
              <Info
                title="Intake PSI"
                statistic={locationEquipment4?.Other?.ioValues?.intake_pressure?.value}
                label={locationEquipment4?.Other?.ioValues?.intake_pressure?.ioValueType?.units}
              />
              <Info
                title="Discharge PSI"
                statistic={locationEquipment4?.Other?.ioValues?.discharge_water_pressure?.value}
                label={locationEquipment4?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
              />
              <Info
                title="Room Temp."
                statistic={useCelsius ? locationEquipment4?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment4?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment4?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment4?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <MultiInfoButton
                title="Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment4?.FT?.ioValues?.trip_volume?.value,
                  locationEquipment4?.FT?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment4?.FT?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment4?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment4?.FT?.ioValues?.trip_volume_reset?.id,
                  locationEquipment4?.FT?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment4?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment4?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(PLC1, language)}
                label=""
                color={PLCColorRouter(PLC1, theme)}
              />
            </InfoControlRow>
            <PumpsRowCustom height="100px" css={styles.hiddenMobile}>
              <div css={styles.drainsColumn}>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Outcast Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment4?.Other?.ioValues?.outcast_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.outcast_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Jennifers Drain"
                    capLabelTop="25px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment4?.Other?.ioValues?.jennifers_run_drain_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.jennifers_run_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
              </div>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={styles.valveCorner}>
                <ValveConnect
                  reverseValve
                  pipeAbove="right"
                  valveData={{}}
                />
              </div>
              <PipePlaceholder horizontalAbove capAfter capLabel="To Oh No" capLabelLeft="-60px" capLabelTop="20px">
                <Statistic
                  float
                  floatColor={theme.pipeColors.water}
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.ohno_pressure?.value}
                  label={locationEquipment4?.Other?.ioValues?.ohno_pressure?.ioValueType?.units}
                />

              </PipePlaceholder>
            </PumpsRowCustom>
            <PumpsRowSmall css={styles.hiddenMobile}>
              <div css={styles.drainsColumn}>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Last Chance Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment4?.Other?.ioValues?.last_chance_drain_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.last_chance_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Hayseed Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment4?.Other?.ioValues?.hayseed_drain_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.hayseed_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
                <div css={styles.drain}>
                  <Pipe
                    horizontal
                    capAfter
                    capLabel="Lower Dutchman Drain"
                    capLabelTop="22px"
                    capLabelLeft="-130px"
                  >
                    <Statistic
                      float
                      floatColor={theme.pipeColors.water}
                      border
                      statistic={locationEquipment4?.Other?.ioValues?.lower_dutchman_drain_pressure?.value}
                      label={locationEquipment4?.Other?.ioValues?.lower_dutchman_drain_pressure?.ioValueType?.units}
                    />
                  </Pipe>
                </div>
              </div>
              <ValveConnect
                reverseValve
                pipeAbove="right"
                valveData={{}}
              />
              <ValveConnect
                reverseValve
                pipeAbove="middle"
                valveData={{}}
              />
              <ValveConnect
                reverseValve
                pipeAbove="middle"
                valveData={{}}
              />
              <ValveConnect
                reverseValve
                pipeAbove="middle"
                valveData={{}}
              />
              <PipePlaceholder horizontalAbove capAfter capLabel="To Needle" capLabelLeft="-65px" capLabelTop="20px">
                <Statistic
                  float
                  floatColor={theme.pipeColors.water}
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.needle_pressure?.value}
                  label={locationEquipment4?.Other?.ioValues?.needle_pressure?.ioValueType?.units}
                />
              </PipePlaceholder>
            </PumpsRowSmall>
            <PumpsRowCustom height="200px">
              <PipePlaceholder horizontalBelow
                capBefore
                capLabel="From Mirror Lake"
                capLabelTop="-30px"
                capLabelLeft="10px"
              >
                <Statistic
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.suction_water_pressure?.value}
                  label={locationEquipment4?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                />
                <Statistic
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.suction_water_flow?.value}
                  label={locationEquipment4?.Other?.ioValues?.suction_water_flow?.ioValueType?.units}
                />
              </PipePlaceholder>
              <Pump
                psi
                percent
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment4.BP1,
                  name: 'BP1',
                  ioValues: {
                    ...locationEquipment4.BP1.ioValues,
                    temperature: useCelsius ? locationEquipment4?.BP1?.ioValues?.seal_temperature_alternate : locationEquipment4?.BP1?.ioValues?.seal_temperature,
                  },
                }}
                otherData={locationEquipment4?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'seal_temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment4?.BP1?.ioValues?.low_pressure,
                  locationEquipment4?.BP1?.ioValues?.low_amps,
                  locationEquipment4?.BP1?.ioValues?.high_seal_temp,
                  locationEquipment4?.BP1?.ioValues?.fault,
                  locationEquipment4?.BP1?.ioValues?.estop,
                  locationEquipment4?.BP1?.ioValues?.comms,
                ]}
                stats={[
                  {
                    stat: locationEquipment4?.BP1?.ioValues?.current?.value,
                    label: locationEquipment4?.BP1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP1?.ioValues?.run_time?.value,
                    label: locationEquipment4?.BP1?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP1?.ioValues?.speed?.value,
                    label: locationEquipment4?.BP1?.ioValues?.speed?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                psi
                percent
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment4.BP2,
                  name: 'BP2',
                  ioValues: {
                    ...locationEquipment4.BP2.ioValues,
                    temperature: useCelsius ? locationEquipment4?.BP2?.ioValues?.seal_temperature_alternate : locationEquipment4?.BP2?.ioValues?.seal_temperature,
                  },
                }}
                otherData={locationEquipment4?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment4?.BP2?.ioValues?.low_pressure,
                  locationEquipment4?.BP2?.ioValues?.low_amps,
                  locationEquipment4?.BP2?.ioValues?.high_seal_temp,
                  locationEquipment4?.BP2?.ioValues?.fault,
                  locationEquipment4?.BP2?.ioValues?.estop,
                  locationEquipment4?.BP2?.ioValues?.comms,
                ]}
                stats={[
                  {
                    stat: locationEquipment4?.BP2?.ioValues?.current?.value,
                    label: locationEquipment4?.BP2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP2?.ioValues?.run_time?.value,
                    label: locationEquipment4?.BP2?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP2?.ioValues?.speed?.value,
                    label: locationEquipment4?.BP2?.ioValues?.speed?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                psi
                percent
                pipeBelow="middle"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment4.BP3,
                  name: 'BP3',
                  ioValues: {
                    ...locationEquipment4.BP3.ioValues,
                    temperature: useCelsius ? locationEquipment4?.BP3?.ioValues?.seal_temperature_alternate : locationEquipment4?.BP3?.ioValues?.seal_temperature,
                  },
                }}
                otherData={locationEquipment4?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment4?.BP3?.ioValues?.low_pressure,
                  locationEquipment4?.BP3?.ioValues?.low_amps,
                  locationEquipment4?.BP3?.ioValues?.high_seal_temp,
                  locationEquipment4?.BP3?.ioValues?.fault,
                  locationEquipment4?.BP3?.ioValues?.estop,
                  locationEquipment4?.BP3?.ioValues?.comms,
                ]}
                stats={[
                  {
                    stat: locationEquipment4?.BP3?.ioValues?.current?.value,
                    label: locationEquipment4?.BP3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP3?.ioValues?.run_time?.value,
                    label: locationEquipment4?.BP3?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP3?.ioValues?.speed?.value,
                    label: locationEquipment4?.BP3?.ioValues?.speed?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                psi
                percent
                pipeBelow="left"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment4.BP4,
                  name: 'BP4',
                  ioValues: {
                    ...locationEquipment4.BP4.ioValues,
                    temperature: useCelsius ? locationEquipment4?.BP4?.ioValues?.seal_temperature_alternate : locationEquipment4?.BP4?.ioValues?.seal_temperature,
                  },
                }}
                otherData={locationEquipment4?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  // This is confusing but we use this key with the custom formatted pumpData up top
                  // so that we can have appropriate precision on our suction temperature
                  speed: 'temperature',
                }}
                writeValues={[1, 1]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment4?.BP4?.ioValues?.low_pressure,
                  locationEquipment4?.BP4?.ioValues?.low_amps,
                  locationEquipment4?.BP4?.ioValues?.high_seal_temp,
                  locationEquipment4?.BP4?.ioValues?.fault,
                  locationEquipment4?.BP4?.ioValues?.estop,
                  locationEquipment4?.BP4?.ioValues?.comms,
                ]}
                stats={[
                  {
                    stat: locationEquipment4?.BP4?.ioValues?.current?.value,
                    label: locationEquipment4?.BP4?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP4?.ioValues?.run_time?.value,
                    label: locationEquipment4?.BP4?.ioValues?.run_time?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.BP4?.ioValues?.speed?.value,
                    label: locationEquipment4?.BP4?.ioValues?.speed?.ioValueType?.units,
                  },
                ]}
              />
              <PipePlaceholder hidden />
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hidden: css`
      display: none;
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    drainsColumn: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
  `,
    valveCorner: css`
      display: flex;
      height: 100%;
      width: 75px;
  `,
  };
};

PumpScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

PumpScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PumpScreen);
