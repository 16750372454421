import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import LoginSVG from 'isno/lib/static/images/login.svg';

function LoginButton(props) {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, LoginSVG);

  const [animation, setAnimation] = useState(props.animate);

  useEffect(() => {
    setAnimation(props.animate);
  }, [props]);

  return (
    <div css={styles.loginButtonContainer}>
      {/* <div css={[css`width: 320px; height: 45px; border: 1px solid ; border-radius: 30px;`, styles.svgAnimation]} /> */}
      {/* <div css={[styles.loginBorder]} /> */}
      {animation && (
      <div css={[styles.gradient2]}>
        <div css={[styles.gradient]} />
      </div>
      )}
      {!animation && (
      <div css={[styles.loginBorder]} />
      )}
      <div css={[animation ? styles.loginButtonTextAuthenticate : styles.loginButtonText, props.language.fileName === 'français' ? css`left: 42%;` : css``]}>{animation ? props.language.authenticating : props.language.login}</div>
    </div>
  );
}

LoginButton.propTypes = {
  animate: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    fileName: PropTypes.string,
    authenticating: PropTypes.string,
    login: PropTypes.string,
  }),
};

LoginButton.defaultProps = {
  language: {
    fileName: 'english',
    authenticating: 'Authenticating',
    login: 'Login',
  },
};

const stylesFromTheme = (theme, img) => {
  return {
    loginButtonContainer: css`
        display: flex; 
        align-items: center;
        justify-content: center;
        position: relative;
        stroke: inherit;
        width: 322px;
        height: 47px;
        border-radius: 50px;
        overflow: hidden;
    `,
    gradient: css`
        background: conic-gradient(transparent 270deg, ${theme.linkColorActive}, transparent);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        aspect-ratio: 1;
        width: 100%;
        animation: rotate 1.5s linear infinite;
        @keyframes rotate {
          from { 
              transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
          }
          to { 
              transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
          }
      }
    `,
    gradient2: css`
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-mask-image: url(${img});
        mask-image: url(${img});
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        mask-position: center;
    `,
    loginBorder: css`
        width: 320px;
        height: 45px;
        border: 1px solid rgb(231, 231, 231);
        border-radius: 30px;
        :hover {
          border: 1px solid ${theme.linkColorActive};
          background: rgba(231, 231, 231, 0.1);
        };
    `,
    loginSpin: css`
        width: 320px;
        height: 45px;
        // border: 1px solid rgb(231, 231, 231);
        // border-radius: 30px;
        // :hover {
        //   border: 1px solid rgba(75, 115, 255, 1);
        //   background: rgba(231, 231, 231, 0.1);
        // };
    `,
    loginButtonText: css`
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        margin-top: -6.5px;
        margin-left: -15px;
        pointer-events: none;
        @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
          margin-top: -10px;
          margin-left: -18.5px;
        }
    `,
    loginButtonTextAuthenticate: css`
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        margin-top: -6.5px;
        margin-left: -40.5px;
        pointer-events: none;
        @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
          margin-top: -10px;
          margin-left: -44px;
        }
    `,
    svgLoginButton: css`
        fill: none;
        fill-opacity:1;
        stroke-linecap:round;
        stroke-miterlimit:4;
        stroke-dasharray:none;
        stroke-opacity:1;
        stroke: inherit;
        stroke-width: 0.3px;
        :focus {
          outline: none;
          stroke: #4B73FF;
        };
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: white;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: white;
        }
        :hover {
          stroke: #4B73FF;
          fill: rgba(80, 80, 82, 0.4);
        }
    `,
    svgAnimation: css`
        fill: rgba(80, 80, 82, 0.4);
        stroke-linecap:round;
        stroke-miterlimit:4;
        stroke-dasharray: 185;
        stroke-opacity:1;
        stroke: #4B73FF;
        stroke-width: 0.3px;
        animation-name: auth;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        @keyframes auth {
            0% { 
                stroke-dashoffset: 185; 
                fill-opacity: 0.1;
            }
            50% {  
                stroke-dashoffset: 0; 
                fill-opacity: 0.9;
            }
            100% {  
                stroke-dashoffset: -185; 
                fill-opacity: 0.1;
            }
        }
    `,
  };
};

export default LoginButton;
