import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pipe,
  PipePlaceholder,
  ValveConnect,
  Compressor,
  IRCompressor,
  PipeCurve,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowCustom,
  PumpsRowSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import AftercoolerFan from 'isno/lib/components/equipment/AftercoolerFan';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

function CompressorScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchLocation(4),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchLocation(4);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  const locationEquipment4 = props.locations?.locations?.[4]?.equipment;
  const PLC1 = props.plcs?.plcs?.[1];
  if (!props.locations?.locations?.[1] || !props.locations?.locations?.[4] || !PLC1) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Main Pumphouse Compressors">
            {/* <InfoControlRow>
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(PLC1)}
                label=""
                color={PLCColorRouter(PLC1, theme)}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
              />
            </InfoControlRow> */}
            <PumpsRowCustom height="140px">
              <div css={[styles.curveConnectAbove, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  minWidth="1px"
                  pipeAbove="right"
                  color={theme.pipeColors.air}
                />
              </div>
              <div css={styles.aftercoolerWrapper}>
                <AftercoolerFan
                  imgStyle="height: 100px; width: 83px;"
                  controlPositionTop="-49px"
                  controlPositionLeft="-25px"
                  pipeAbove="middle"
                  fanData={{
                  // ...locationEquipment1?.['Building Fan'],
                    name: 'AC 1',
                    // state: locationEquipment1?.Other?.ioValues?.ac1_run_feedback?.value,
                  }}
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                  minWidth="100px"
                />
              </div>
              <div css={styles.aftercoolerWrapper}>
                <AftercoolerFan
                  imgStyle="height: 100px; width: 83px;"
                  controlPositionTop="-49px"
                  controlPositionLeft="-25px"
                  pipeAbove="middle"
                  fanData={{
                  // ...locationEquipment1?.['Building Fan'],
                    name: 'AC 2',
                    // state: locationEquipment1?.Other?.ioValues?.ac2_run_feedback?.value,
                  }}
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                  minWidth="100px"
                />
              </div>
              <div css={styles.aftercoolerWrapper}>
                <AftercoolerFan
                  imgStyle="height: 100px; width: 83px;"
                  controlPositionTop="-49px"
                  controlPositionLeft="-25px"
                  pipeAbove="middle"
                  fanData={{
                  // ...locationEquipment1?.['Building Fan'],
                    name: 'AC 3',
                    // state: locationEquipment1?.Other?.ioValues?.ac3_run_feedback?.value,
                  }}
                />
              </div>
              <div css={[styles.aftercoolerPipe, styles.aftercoolerPipePadding]}>
                <PipePlaceholder
                  horizontalAbove
                  color={theme.pipeColors.air}
                  minWidth="100px"
                  capAfter
                />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="140px">
              <div css={styles.curveConnectAbove}>
                <PipeCurve color={theme.pipeColors.air} size="medium" right up />
              </div>
              <PipePlaceholder
                horizontalAbove
                color={theme.pipeColors.air}
              >
                <Statistic
                  label={locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.discharge_air_pressure?.value}
                />
                <Statistic
                  label={locationEquipment4?.Other?.ioValues?.discharge_air_flow?.ioValueType?.units}
                  border
                  statistic={locationEquipment4?.Other?.ioValues?.discharge_air_flow?.value}
                />
              </PipePlaceholder>
              <IRCompressor
                viewOnly
                pipeAbove="middle"
                controlPositionTop="-45px"
                controlPositionLeft="14px"
                pipeTypeBelow={[false, false, false]}
                location={props.locations?.locations?.[1]?.name}
                compressorData={{ name: 'C1', status: locationEquipment1?.['C1 Power']?.ioValues?.phase_a_current?.value >= 10 ? 1 : 0 }}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                stats={[
                  {
                    stat: locationEquipment1?.['C1 Power']?.ioValues?.phase_a_current?.value,
                    label: locationEquipment1?.['C1 Power']?.ioValues?.phase_a_current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment4?.Other?.ioValues?.c1_run_time?.value,
                    label: locationEquipment4?.Other?.ioValues?.c1_run_time?.ioValueType?.units,
                  },
                ]}
              >
                {/* <Statistic
                  float
                  floatColor={theme.pipeColors.air}
                  label={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
                  border
                  statistic={locationEquipment1?.Other?.ioValues?.discharge_air_pressure?.value}
                />
                <Pipe color={theme.pipeColors.air} horizontal>
                  <Statistic
                    float
                    floatColor={theme.pipeColors.air}
                    label={locationEquipment1?.Other?.ioValues?.discharge_air_flow?.ioValueType?.units}
                    border
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_air_flow?.value}
                  />
                </Pipe> */}
              </IRCompressor>
              <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment1?.C2}
                pipeAbove="middle"
                controlPositionTop="-45px"
                controlPositionLeft="14px"
                pipeTypeBelow={[false, false, false]}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  compressorStartCommand: 'start',
                  compressorStopCommand: 'stop',
                  compressorModulate: 'load',
                  compressorModulateCommand: 'load',
                  compressorUnloaded: 'unload',
                  compressorUnloadCommand: 'unload',
                  compressorPressureSetpointCommand: 'system_pressure_setpoint',
                  compressorResetCommand: 'alarm_reset',
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: useCelsius ? 'stage_1_temperature_alternate' : 'stage_1_temperature',
                  stage1Pressure: 'stage_1_pressure',
                  stage1Vibration: 'stage_1_vibration',
                  stage2Temperature: useCelsius ? 'stage_2_temperature_alternate' : 'stage_2_temperature',
                  stage2Pressure: 'stage_2_pressure',
                  stage2Vibration: 'stage_2_vibration',
                  stage3Temperature: useCelsius ? 'stage_3_temperature_alternate' : 'stage_3_temperature',
                  stage3Vibration: 'stage_3_vibration',
                }}
                statuses={[
                  {
                    id: locationEquipment1?.C2?.id,
                    label: 'Status',
                    status: locationEquipment1?.C2?.textStatusKey,
                    statusKey: {
                      0: '',
                      1: 'Waiting',
                      2: 'Coasting',
                      3: 'Starting',
                      4: 'Not Ready',
                      5: 'Ready',
                      6: 'Surge Unload',
                      7: 'Auto Dual Unload',
                      8: 'Unloading',
                      9: 'Unloaded',
                      10: 'Min Load',
                      11: 'Max Load',
                      12: 'Loading',
                      13: 'Loaded',
                      14: 'Full Load',
                      15: 'Alarm',
                    },
                    statusColorKey: {
                      0: theme.auto,
                      1: theme.offRed,
                      2: theme.offRed,
                      3: theme.offRed,
                      4: theme.offRed,
                      5: theme.onGreen,
                      6: theme.offRed,
                      7: theme.offRed,
                      8: theme.offRed,
                      9: theme.autoStop,
                      10: theme.offRed,
                      11: theme.offRed,
                      12: theme.offRed,
                      13: theme.local,
                      14: theme.offRed,
                      15: theme.alarm,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.C2?.ioValues?.current?.value,
                    label: locationEquipment1?.C2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                column1Info={{
                  statistics: [
                    locationEquipment1?.C2?.ioValues?.system_pressure?.value,
                    locationEquipment1?.C2?.ioValues?.oil_pressure?.value,
                    locationEquipment1?.C2?.ioValues?.oil_temperature?.value,
                  ],
                  subtitles: [
                    'System Pressure',
                    'Oil Pressure',
                    'Oil Temp',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C2?.ioValues?.system_pressure?.ioValueType?.units,
                    locationEquipment1?.C2?.ioValues?.oil_pressure?.ioValueType?.units,
                    locationEquipment1?.C2?.ioValues?.oil_temperature?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C2?.ioValues?.system_pressure?.id,
                    locationEquipment1?.C2?.ioValues?.oil_pressure?.id,
                    locationEquipment1?.C2?.ioValues?.oil_temperature?.id,
                  ],
                  precisions: [0, 0, 1],
                  mappings: [undefined, undefined, undefined],
                }}
                column2Info={{
                  statistics: [
                    locationEquipment1?.C2?.ioValues?.inlet_valve?.value,
                    locationEquipment1?.C2?.ioValues?.bypass_valve?.value,
                  ],
                  subtitles: [
                    'Inlet Valve',
                    'Bypass Valve',
                  ],
                  // ['Psi', 'Amp', 'Psi', '°F'], // May need these explicit units for better formatting
                  labels: [
                    locationEquipment1?.C2?.ioValues?.inlet_valve?.ioValueType?.units,
                    locationEquipment1?.C2?.ioValues?.bypass_valve?.ioValueType?.units,
                  ],
                  ids: [
                    locationEquipment1?.C2?.ioValues?.inlet_valve?.id,
                    locationEquipment1?.C2?.ioValues?.bypass_valve?.id,
                  ],
                  precisions: [0, 0],
                  mappings: [undefined, undefined],
                }}
              />
              {/* <IRCompressor
                location={props.locations?.locations?.[1]?.name}
                compressorData={locationEquipment1?.C2}
                pipeAbove="left"
                piece2MaxWidth="45px"
                pipeTypeAbove={[false, true, false]}
                capAfter
                pipeTypeBelow={[false, false, false]}
                pipeColorAbove={theme.pipeColors.air}
                setIOValue={props.setIOValue}
                stateColorKey={{ // check our state color map too
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{ // SHOULD REALLY CHECK ALL OF THESE
                  compressorStartCommand: 'start',
                  compressorStopCommand: 'stop',
                  compressorModulate: 'compressor_modulate', // CHECK THIS ONE
                  compressorModulateCommand: 'compressor_modulate_command', // CHECK THIS ONE
                  compressorUnloaded: 'unloaded', // CHECK THIS ONE
                  compressorUnloadCommand: 'unload', // CHECK THIS ONE, should we add load functinality as well?
                  compressorResetCommand: 'alarm_reset', // CHECK THIS ONE
                  compressorPressureSetpointCommand: 'system_pressure_setpoint', // CHECK THIS ONE, we previously had a unique system_pressure_setpoint_command value
                  systemPressureSetpoint: 'system_pressure_setpoint',
                  stage1Temperature: useCelsius ? 'stage_1_temperature_alternate' : 'stage_1_temperature',
                  stage1Pressure: 'stage_1_pressure',
                  stage1Vibration: 'stage_1_vibration',
                  stage2Temperature: useCelsius ? 'stage_2_temperature_alternate' : 'stage_2_temperature',
                  stage2Pressure: 'stage_2_pressure',
                  stage2Vibration: 'stage_2_vibration',
                  stage3Temperature: useCelsius ? 'stage_3_temperature_alternate' : 'stage_3_temperature',
                  stage3Pressure: 'stage_3_pressure',
                  stage3Vibration: 'stage_3_vibration',
                }}
                stats={[
                  {
                    stat: locationEquipment1?.C2?.ioValues?.current?.value,
                    label: locationEquipment1?.C2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.C2?.ioValues?.run_time?.value,
                    label: locationEquipment1?.C2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
              /> */}
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    drainsColumn: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
  `,
    valveCorner: css`
      display: flex;
      height: 100%;
      width: 75px;
  `,
    curveConnectAbove: css`
      align-items: flex-start;
      max-width: 100px;
      display: flex;
      height: 100%;
  `,
    aftercoolerWrapper: css`
      width: 83px;
      height: 100%;
    `,
    aftercoolerPipe: css`
      flex: 1;
      height: 100%;
    `,
    aftercoolerPipePadding: css`
      padding-top: 40px;
    `,
  };
};

CompressorScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

CompressorScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(CompressorScreen);
