import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

// axios.interceptors.request.use((req) => {
//   console.log(`${req.method} ${req.url}`);
//   // Important: request interceptors **must** return the request.
//   return req;
// });

// axios.interceptors.response.use((res) => {
//   console.log(res.data.json);
//   // Important: response interceptors **must** return the response.
//   return res;
// });

export function fetchAllGateways(cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/gateways/`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_ALL_GATEWAYS_SUCCESS, payload: response.data });
      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        return error.message;
      }
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
      return error;
    }
  };
}

export function fetchGateway(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/gateways/${id}`,
        headers: { Authorization: `Bearer ${token}` },

      });
      dispatch({ type: ActionTypes.FETCH_GATEWAY_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}

export function addGateway(data) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/gateways`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      dispatch({ type: ActionTypes.ADD_GATEWAY_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}

export function deleteGateway(id) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'delete',
        url: `${ROOT_URL}/gateways/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_GATEWAY_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}

export function fetchNodes(id, cancelToken) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/gateways/${id}/nodes`,
        headers: { Authorization: `Bearer ${token}` },
        cancelToken,
      });
      dispatch({ type: ActionTypes.FETCH_NODES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}

export function addNode(id, name) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/gateways/${id}/addNode/${name}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: ActionTypes.ADD_NODE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}

export function deleteNode(id, name) {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      await axios({
        method: 'delete',
        url: `${ROOT_URL}/gateways/${id}/removeNode/${name}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: ActionTypes.DELETE_NODE_SUCCESS, payload: name });
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_GATEWAYS_ERROR, payload: error });
    }
  };
}
